/**
 * @package     Wordpress.Site
 * @subpackage  Templates.NoName
 *
 * @copyright   Copyright (C) 2020 NoName. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */

import(/* webpackPrefetch: true */ "../utils/header.js");
import(/* webpackPrefetch: true */ "../utils/footer.js");
import(/* webpackPrefetch: true */ "../utils/icon.js");

//Load fontface
import(/* webpackChunkName: "fontfaceobserver" */ "fontfaceobserver").then(
  (module) => {
    const FontFaceObserver = module.default;

    const fontGilroy = new FontFaceObserver("Gilroy");
    const fontGilroyMedium = new FontFaceObserver("Gilroy-Medium");
    const fontGilroySemiBold = new FontFaceObserver("Gilroy-SemiBold");

    Promise.all([
      fontGilroy.load(),
      fontGilroyMedium.load(),
      fontGilroySemiBold.load(),
    ]).then(function () {
      document.documentElement.style.setProperty(
        "--bs-font-sans-serif",
        "Gilroy, sans-serif"
      );
      document.documentElement.style.setProperty(
        "--bs-font-sans-serif-medium",
        "Gilroy-Medium, sans-serif"
      );
      document.documentElement.style.setProperty(
        "--bs-font-sans-serif-semiBold",
        "Gilroy-SemiBold, sans-serif"
      );
    });
  }
);
